import React from 'react'
import { FieldError, useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { AmplitudeFeatureFlag } from '@genoa/experiments'
import { getRentAmountContent, RENT_AMOUNT } from '@genoa/screen-content'
import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useContentOverhaul, useFlexAnywhereFlywheelExperiment, useFlexAnywhereUser } from '../../../../hooks'
import { useInNetworkFlexAnywhereUser } from '../../../../hooks/flexAnywhere/use-in-network-flex-anywhere-user'
import { useAmplitudeFeatureFlag } from '../../../../hooks/use-amplitude-feature-flag'
import { RentAmountState } from '../../../../modules'
import { Field, Headline1, LinkButton, MoneyInput, PrimaryButton, SmallText } from '../../../components'
import { BasePageLayout } from '../../../layouts'
import { rentValidator } from './common'
import { RentAmountChanges } from './RentAmountChanges'

export type InitiateOfferRentAmountProps = {
  submitRentAmount: (RentAmount: RentAmountState) => unknown
  rentAmount?: RentAmountState
  isLoading: boolean
  errors: {
    propertyName?: FieldError
    amount?: FieldError
  }
  onNext: ({
    amount,
    propertyName,
    propertyUrl,
    propertyEmail,
  }: {
    amount: number
    propertyName?: string
    propertyUrl?: string
    propertyEmail?: string
  }) => void
  onBack?: () => unknown
  analyticsScreenName: Analytics.Screens
  rentAmountChangeModalVisible: boolean
  setRentAmountChangeModalVisible: (value: boolean) => unknown
  showConfirmText: boolean
  showSettings?: boolean
}

const rentAmountSchema = yup.object({
  amount: rentValidator,
  propertyName: yup.string(),
})

const rentAmountSchemaFA = yup.object({
  amount: rentValidator,
  propertyName: yup.string().required('Property name is required'),
})

const rentAmountSchemaFAFlywheel = yup.object().shape({
  amount: rentValidator,
  propertyName: yup.string().required('Property name is required'),
  propertyUrl: yup.string().required('Website is required'),
})

export const InitiateOfferRentAmount = (props: InitiateOfferRentAmountProps) => {
  const isFlexAnywhere = useFlexAnywhereUser()
  const { isRentAmountEnabled: contentOverhaulEnabled } = useContentOverhaul()
  const { enabled: isPropertyNameFieldEnabled } = useAmplitudeFeatureFlag(AmplitudeFeatureFlag.PropertyNameEnabled)
  const { isFlexAnywhereFlywheelEnabled } = useFlexAnywhereFlywheelExperiment()
  const [query] = useSearchParams()
  const propertyEmail = query.get('propertyEmail') || ''
  const isInNetwork = useInNetworkFlexAnywhereUser()
  const isFAFlywheelEnabled = isFlexAnywhere && isFlexAnywhereFlywheelEnabled && !isInNetwork

  const getValidationSchema = ({
    isFlexAnywhere,
    isFAFlywheelEnabled,
    isPropertyNameFieldEnabled,
  }: {
    isFlexAnywhere: boolean
    isFAFlywheelEnabled: boolean
    isPropertyNameFieldEnabled: boolean
  }) => {
    if (isFAFlywheelEnabled && isPropertyNameFieldEnabled) {
      return rentAmountSchemaFAFlywheel
    } else if (isFlexAnywhere && isPropertyNameFieldEnabled) {
      return rentAmountSchemaFA
    }
    return rentAmountSchema
  }

  const {
    control,
    handleSubmit,
    formState: { errors: errorsForm },
  } = useForm<RentAmountState>({
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema({ isFlexAnywhere, isFAFlywheelEnabled, isPropertyNameFieldEnabled })),
  })

  const onSubmit = handleSubmit((data: RentAmountState) => {
    if (!props.isLoading && data.amount) {
      props.submitRentAmount(data)
      const { amount, propertyName, propertyUrl } = data
      props.onNext({ amount: parseFloat(amount), propertyName, propertyUrl, propertyEmail })
    }
  })

  const content = getRentAmountContent({
    isFlexAnywhere,
    isFAFlywheelEnabled,
  })

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName} showSettings={props.showSettings}>
      <Box minH="30px" />

      <Box flex={1} px="lg">
        <Box>
          <Headline1 data-testid="RentAmountHeader">
            {props.showConfirmText ? RENT_AMOUNT.CONFIRM_HEADER : content.HEADER}
          </Headline1>

          <Box minH="24px" />

          <SmallTextStyled data-testid="RentAmountBody">{content.BODY}</SmallTextStyled>
        </Box>

        {!contentOverhaulEnabled && !isFAFlywheelEnabled && (
          <>
            <Box minH="32px" />
            <LinkButton testID="RentAmountWhatIf" onClick={() => props.setRentAmountChangeModalVisible(true)}>
              {RENT_AMOUNT.AMOUNT_CHANGES}
            </LinkButton>
          </>
        )}

        <Box minH="32px" />

        {isFlexAnywhere && isPropertyNameFieldEnabled && (
          <>
            <Field
              label={content.PROPERTY_NAME_INPUT_LABEL}
              name="propertyName"
              control={control}
              defaultValue={props.rentAmount?.propertyName}
              error={errorsForm.propertyName || props.errors.propertyName}
              testID="PropertyNameInput"
              helperText={RENT_AMOUNT.PROPERTY_NAME_HELPER_TEXT}
            />
            <Box minH="32px" />
          </>
        )}

        {props.rentAmount && (
          <Field
            input={MoneyInput}
            label={content.INPUT_LABEL}
            name="amount"
            control={control}
            defaultValue={props.rentAmount.amount}
            error={errorsForm.amount || props.errors.amount}
            testID="RentAmountMonthlyRentInput"
            helperText={contentOverhaulEnabled ? RENT_AMOUNT.HELPER_TEXT : ''}
          />
        )}

        <Box minH="32px" />

        {isFAFlywheelEnabled && (
          <Field
            name="propertyUrl"
            control={control}
            label={content.PORTAL_INPUT_LABEL}
            placeholder={content.PORTAL_INPUT_PLACEHOLDER}
            helperText={content.PORTAL_INPUT_HELPER_TEXT}
            error={errorsForm.propertyUrl}
          />
        )}
        <Box minH="84px" />
        <Box>
          <PrimaryButton
            testID="RentAmountNext"
            onClick={onSubmit}
            disabled={props.isLoading}
            processing={props.isLoading}
          >
            {RENT_AMOUNT.CTA}
          </PrimaryButton>
        </Box>
      </Box>
      <Box style={{ zIndex: 2 }}>
        <RentAmountChanges
          visible={props.rentAmountChangeModalVisible}
          header={RENT_AMOUNT.AMOUNT_CHANGES_MODAL.HEADER}
          bodyOne={RENT_AMOUNT.AMOUNT_CHANGES_MODAL.BODY_ONE}
          bodyTwo={RENT_AMOUNT.AMOUNT_CHANGES_MODAL.BODY_TWO}
          cta={RENT_AMOUNT.AMOUNT_CHANGES_MODAL.CTA}
          onClose={() => props.setRentAmountChangeModalVisible(false)}
        />
      </Box>
    </BasePageLayout>
  )
}

const SmallTextStyled = styled(SmallText)`
  margin-bottom: 0;
`
