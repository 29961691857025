import React, { useCallback, useEffect, useState } from 'react'
import { Analytics } from '@genoa/analytics'
import { BillerFlexAnywhereLongTailPortal, IntegrationType, OON_BILLER_ID, OpenSearchProperty } from '@genoa/domain'
import { useGetBillerPropertyDetails, useGetBillerPropertyPortalDetails, usePropertiesSearch } from '@genoa/middle-end'
import {
  setBillerGenericPortalAction,
  setBillerPropertyDetailsAction,
  setBillerPropertyPortalDetailsAction,
  setOpenSearchPropertyAction,
} from '@genoa/state-management'
import debounce from 'lodash/debounce'

import { useModal } from '../../../../../contexts'
import { useReduxAction, useSecureLineOfCredit } from '../../../../../hooks'
import { logger, useAnalytics, useIterable } from '../../../../../providers'
import { SmallText } from '../../../../components'
import { AddressSelection } from './AddressSelection'
import { usePropertyNotSupportedModal } from './use-property-not-supported-modal'

interface AddressSelectionContainerProps {
  readonly onBack?: () => unknown
  readonly analyticsScreenName: Analytics.Screens
  readonly analyticsEventSigninClicked: Analytics.Events
  readonly continueEvent: Analytics.Events
  readonly onDirectIntegrationPropertySelected: () => unknown
  readonly onGenericPortalPropertySelected: () => unknown
  readonly onNonGenericPortalPropertySelected: () => unknown
  readonly onDontSeeMyProperty: () => unknown
  readonly onNavigateToFAHowYouPay: () => unknown
}

export const AddressSelectionContainer = (props: AddressSelectionContainerProps) => {
  const analytics = useAnalytics()
  const iterable = useIterable()

  const { isFlagEnabledForCreditBuilder } = useSecureLineOfCredit()
  const { showPropertyNotSupportedModal } = usePropertyNotSupportedModal()

  const [, getBillerPropertyDetails] = useGetBillerPropertyDetails()
  const [, getBillerPropertyPortalDetails] = useGetBillerPropertyPortalDetails()

  const setBillerPropertyDetails = useReduxAction(setBillerPropertyDetailsAction)
  const setBillerPropertyPortalDetails = useReduxAction(setBillerPropertyPortalDetailsAction)
  const setBillerGenericPortal = useReduxAction(setBillerGenericPortalAction)
  const setOpenSearchProperty = useReduxAction(setOpenSearchPropertyAction)

  const [, getProperties] = usePropertiesSearch()
  const [query, setQuery] = useState('')

  const [loadingLocationData, setLoadingLocationData] = useState(false)
  const [geoCoordinates, setGeoCoordinates] = useState<GeolocationCoordinates | undefined>(undefined)
  const [properties, setProperties] = useState<readonly OpenSearchProperty[]>([])
  const [confirming, setConfirming] = useState(false)

  const modal = useModal()

  const handleNextError = useCallback(() => {
    modal.show({
      title: 'Whoops!',
      cta: 'Got it',
      render: () => (
        <SmallText>
          We had some problems connecting to your address. Please go back and try to select your address again.
        </SmallText>
      ),
    })
  }, [modal])

  const handleDontSeeMyProperty = useCallback(() => {
    analytics.logEvent(Analytics.Events.PROPERTY_SEARCH_CANT_FIND_CLICKED, {
      searchLength: query.length,
      locationEnabled: geoCoordinates !== undefined,
    })

    if (isFlagEnabledForCreditBuilder) {
      showPropertyNotSupportedModal(OON_BILLER_ID)
      return
    }

    props.onDontSeeMyProperty()
  }, [query, geoCoordinates, isFlagEnabledForCreditBuilder])

  const onRequestLocationData = useCallback(() => {
    if (!loadingLocationData) {
      setLoadingLocationData(true)

      if (geoCoordinates !== undefined) {
        setGeoCoordinates(undefined)
        return setLoadingLocationData(false)
      }

      try {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setGeoCoordinates(position.coords)
            setLoadingLocationData(false)
          },
          (error) => {
            logger.error('AddressSelectionContainer', 'failed to access location data', error.message)
            setLoadingLocationData(false)
          }
        )
      } catch {
        logger.error('AddressSelectionContainer', 'failed to access location data')
        setLoadingLocationData(false)
      }
    }
  }, [geoCoordinates, loadingLocationData])

  const onSelect = async (selected: OpenSearchProperty, index: number) => {
    if (selected) {
      setConfirming(true)
      try {
        setOpenSearchProperty(selected)
        iterable.setPropertyInformation({
          property_name: selected.property_name,
          property_id: selected.property_id,
          pmc_id: selected.pmc_id,
        })

        analytics.logEvent(props.continueEvent, {
          propertyName: selected.property_name,
          propertyId: selected.property_id,
          searchLength: query.length,
          locationEnabled: geoCoordinates !== undefined,
          selectedIndex: index,
        })

        const propertyResponse = await getBillerPropertyDetails({ propertyId: selected.biller_property_id })
        const property = propertyResponse.data
        setBillerPropertyDetails(property)

        // Checking here if property is entrata to exclude from credit builder, TODO is to move this check to OC
        if (
          isFlagEnabledForCreditBuilder &&
          (property.integration_type === IntegrationType.PORTAL ||
            property.integration_type === IntegrationType.FLEX_ANYWHERE ||
            property.billing_integration_type === 'ENTRATA')
        ) {
          showPropertyNotSupportedModal(selected.biller_property_id)
          return
        }

        if (property.integration_type === IntegrationType.PORTAL) {
          const portalDetailsResponse = await getBillerPropertyPortalDetails({
            propertyId: selected.biller_property_id,
          })
          setBillerPropertyPortalDetails(portalDetailsResponse.data)

          const propertyName = property?.name || ''
          const portalName = portalDetailsResponse.data?.portal_name || propertyName || ''
          analytics.logEvent(props.analyticsEventSigninClicked, { portal: portalName, property: propertyName })

          props.onNonGenericPortalPropertySelected()
        } else if (property.integration_type === IntegrationType.FLEX_ANYWHERE) {
          analytics.logEvent(Analytics.Events.ADDRESS_SELECTION_FLEX_ANYWR_PROP_CLKD, {
            portalId: selected.biller_property_id,
          })

          const portalDetails: BillerFlexAnywhereLongTailPortal = {
            public_id: property.public_id,
            portal_name: property.name,
            display_name: property.name,
            status: property.status,
            portal_display_config: undefined,
            biller_id: property.identity_biller_id,
          }

          setBillerGenericPortal(portalDetails)
          props.onNavigateToFAHowYouPay()
        } else {
          props.onDirectIntegrationPropertySelected()
        }
      } catch (error: any) {
        logger.error('AddressSelectionContainer', error?.message, error?.stack)
        handleNextError()
      } finally {
        setConfirming(false)
      }
    }
  }

  const updatePropertiesList = useCallback(
    async (searchTerm: string, coordinates?: GeolocationCoordinates) => {
      const response = await getProperties({ searchTerm, coordinates })
      if (response.status >= 200 && response.status < 300 && Array.isArray(response.data.property_search_results)) {
        return setProperties(response.data.property_search_results)
      }
    },
    [getProperties]
  )

  const debouncedFetch = useCallback(
    debounce((input: string, geoCoordinates?: GeolocationCoordinates) => {
      analytics.logEvent(Analytics.Events.PROPERTY_SEARCH_ADDRESS_SEARCH_PERFORMED, {
        searchLength: input.length,
        locationEnabled: geoCoordinates !== undefined,
      })

      updatePropertiesList(input.toLowerCase(), geoCoordinates)
    }, 500),
    []
  )

  useEffect(() => {
    if (query.length >= 3) {
      return debouncedFetch(query.toLowerCase(), geoCoordinates)
    }
    debouncedFetch.cancel()
    if (query.length === 0) {
      setProperties([])
    }
  }, [query, geoCoordinates])

  return (
    <AddressSelection
      analyticsScreenName={props.analyticsScreenName}
      onChangeQuery={setQuery}
      onSelect={onSelect}
      properties={properties}
      loadingNext={confirming}
      onRequestLocationData={onRequestLocationData}
      loadingLocationData={loadingLocationData}
      loadedLocationData={geoCoordinates !== undefined}
      onDontSeeMyProperty={handleDontSeeMyProperty}
    />
  )
}
