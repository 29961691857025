import { useEffect, useState } from 'react'
import { OfferStates, OnboardingStep, OnboardingStepStatus } from '@genoa/middle-end'

import { setOfferAction } from '../../../modules/flex2/offer'
import { useOfferState, useOnboardingStatusState } from '../../../modules/flex2/use-state'
import { useContinueEvaluationMutation } from '../../../modules/flexApi'
import { useAccount } from '../../use-account'
import { useReduxAction } from '../../use-redux-action'
import { isBlockedOffer } from './offer-states'

const deniedStates = [OfferStates.DENIED.toString(), OfferStates.WITHDRAWN.toString()]

export const useOfferDenialState = () => {
  const onboardingStatusState = useOnboardingStatusState()
  const offerState = useOfferState()
  const setOfferState = useReduxAction(setOfferAction)
  const { isAccountLoaded, customer } = useAccount()

  const [isLoading, setIsLoading] = useState(true)
  const [deniedOffer, setDeniedOffer] = useState(false)
  useEffect(() => {
    if (isAccountLoaded) {
      const creditEligibilityCompleted = onboardingStatusState?.steps.find(
        (step) => step.step === OnboardingStep.CREDIT_ELIGIBILITY_CHECK && step.status === OnboardingStepStatus.COMPLETE
      )

      const shouldShowDeniedOffer =
        onboardingStatusState.loaded &&
        offerState.initialized &&
        deniedStates.includes(offerState.offer?.offer_state) &&
        offerState.offer.withdrawal_reason !== 'Expiration' &&
        creditEligibilityCompleted

      if (shouldShowDeniedOffer || isBlockedOffer(offerState)) {
        setDeniedOffer(true)
      }

      setIsLoading(false)
    }
  }, [isAccountLoaded, onboardingStatusState])

  return { loading: isLoading, deniedOffer }
}
