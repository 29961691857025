import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { EMBED_SIMPLIFIED_PAYMENTS as content, replaceContent } from '@genoa/screen-content'
import { capitalizeFirstLetter, toFormattedDollars } from '@genoa/utils'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useEmbedAnalytics, useSecureLineOfCredit } from '../../../../../../hooks'
import * as Routes from '../../../../../../routing/constants'
import { CardIcon } from '../../../../../assets'
import { Gap, Headline2, PrimaryButton, SmallText } from '../../../../../components'
import { PaymentScheduleItemProps } from '../../../../../components/PaymentSchedule/PaymentScheduleItem'
import { BasePageLayout } from '../../../../../layouts'
import { PaymentBox, PaymentRow } from './PaymentBox'

interface SimplifiedEpisodicProps {
  cardLast4Digits: string
  cardType: string
  disabled: boolean
  downPaymentAmount: string | undefined
  handlePayButtonPress: () => void
  loading: boolean
  propertyName: string
  secondPayment: PaymentScheduleItemProps | undefined
  totalAmount: number
}

export const SimplifiedEpisodic = (props: SimplifiedEpisodicProps) => {
  const { isEnabledForSLC } = useSecureLineOfCredit()
  const { embedProperties } = useEmbedAnalytics()
  const navigate = useNavigate()

  const onChangeCard = () => {
    navigate(Routes.Embed.CHANGE_CARD)
  }

  const Card = () => (
    <CardContainer>
      <CardIcon />
      <CardInfo>{`${capitalizeFirstLetter(props.cardType)} card x${props.cardLast4Digits}`}</CardInfo>
    </CardContainer>
  )

  const ChangeCardContainer = () => <ChangeCard onClick={onChangeCard}>Change</ChangeCard>

  const firstPayment: PaymentRow[] = [
    { leftSide: <SmallText>{content.FIRST_PAYMENT_DUE}</SmallText>, rightSide: props.downPaymentAmount },
    { leftSide: <Card />, rightSide: <ChangeCardContainer /> },
  ]

  const payTo: PaymentRow[] = [
    { leftSide: <SmallText>{content.FLEX_RENT_PAY_TO}</SmallText>, rightSide: props.propertyName },
    { leftSide: <SmallText>{content.FLEX_RENT_AMOUNT}</SmallText>, rightSide: toFormattedDollars(props.totalAmount) },
  ]

  // Remove day of week like "Monday, " from date "Monday, Aug 15"
  const secondDueDate = props.secondPayment?.lineItems?.[0]?.label?.replace(/^\w+,\s(?=.+)/, '') || ''
  const secondPayment: PaymentRow[] = [
    {
      leftSide: <SmallText>{replaceContent(content.SECOND_PAYMENT_DUE, { dueDate: secondDueDate })}</SmallText>,
      rightSide: String(props.secondPayment?.amount || ''),
    },
    { leftSide: <Card /> },
  ]

  const firstPaymentHeader = replaceContent(content.FIRST_PAYMENT_HEADER, {
    paymentType: isEnabledForSLC ? 'deposit' : '1st payment',
  })

  return (
    <BasePageLayout
      analyticsScreenName={Analytics.Screens.EMBED_NON_SLC_PAY_RENT}
      analyticsScreenParams={embedProperties}
      showSettings
    >
      <Container flex={1} flexDirection="column" px="md">
        <Gap size="spacing_200" />

        <Headline2>{content.HEADER}</Headline2>
        <Gap size="spacing_100" />

        <SmallText>{content.BODY}</SmallText>
        <Gap size="spacing_200" />

        <PaymentBox header={firstPaymentHeader} rows={firstPayment} testId="PayNowFirstPayment" />
        <Gap size="spacing_150" />
        <PaymentBox header={content.FLEX_RENT_HEADER} rows={payTo} testId="PayNowPayTo" />
        <Gap size="spacing_150" />
        <PaymentBox
          header={replaceContent(content.SECOND_PAYMENT_HEADER, { dueDate: secondDueDate })}
          rows={secondPayment}
          testId="PayNowSecondPayment"
        />
        <Gap size="spacing_150" />

        <Box>
          <PrimaryButton
            disabled={props.disabled}
            onClick={props.handlePayButtonPress}
            testID="PayNowCTAButton"
            processing={props.loading}
          >
            {content.CTA}
          </PrimaryButton>
          <Gap size="spacing_225" />
        </Box>
      </Container>
    </BasePageLayout>
  )
}

const Container = styled(Box)`
  height: 100%;
`

const CardContainer = styled(Flex)`
  align-items: center;
  flex-direction: row;
`

const CardInfo = styled(SmallText)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-left: ${({ theme }) => theme.fixedSizes.spacing_100};
`

const ChangeCard = styled(SmallText)`
  color: ${({ theme }) => theme.colors.jewelPurple};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-left: ${({ theme }) => theme.fixedSizes.spacing_100};
`
