import { AprilBpConsolidateCardScreenExperiment } from '@genoa/experiments'

import { useExperiment } from './use-experiment'

interface AprilBpConsolidateCardScreen {
  consolidateCardScreen: boolean
}

const CONTROL = false

export const useAprilBpConsolidateCardScreen = (): AprilBpConsolidateCardScreen => {
  const { variantPayload } = useExperiment(AprilBpConsolidateCardScreenExperiment)
  if (variantPayload) {
    return variantPayload
  }
  return {
    consolidateCardScreen: CONTROL,
  }
}
