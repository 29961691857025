import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { INVITE_YOUR_PROPERTY as content } from '@genoa/screen-content'
import { Box, Stack } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useAccount } from '../../../../hooks/use-account'
import { useLogger } from '../../../../providers'
import * as Routes from '../../../../routing/constants'
import { PaperAirplane } from '../../../assets'
import { Field, Headline2, PrimaryButton, SmallText } from '../../../components'
import { BasePageLayout } from '../../../layouts'

const InviteYourProperty = () => {
  const { customer } = useAccount()
  const logger = useLogger('InviteYourProperty')
  const navigate = useNavigate()

  const {
    HEADER,
    BODY_ONE,
    BODY_TWO,
    BUTTON_TEXT,
    BUTTON_DONE_TEXT,
    PROPERTY_FIELD_PLACEHOLDER,
    CTA,
    SUBJECT_LINE,
    EMAIL_BODY,
  } = content

  const theme = useTheme()
  const [propertyEmail, setPropertyEmail] = useState('')
  const [isInviteGenerated, setIsInviteGenerated] = useState(false)

  const schema = yup.object().shape({
    propertyEmail: yup.string().required(),
  })

  const formProps = useForm({ resolver: yupResolver(schema) })
  const {
    formState: { isValid, isSubmitting },
    handleSubmit,
    control,
  } = formProps

  const openMailTo = ({
    recipient,
    subject,
    body,
    cc,
  }: {
    recipient: string
    subject: string
    body: string
    cc: string
  }) => {
    const encodedRecipient = encodeURIComponent(recipient)
    const encodedSubject = encodeURIComponent(subject)
    const encodedBody = encodeURIComponent(`${body}\n\n${customer?.first_name} ${customer?.last_name}`)
    const encodedCc = encodeURIComponent(cc)

    const mailToLink = `mailto:${encodedRecipient}?subject=${encodedSubject}&body=${encodedBody}&cc=${encodedCc}`

    window.location.href = mailToLink
  }

  const openMailToLink = async () => {
    try {
      setIsInviteGenerated(true)
      openMailTo({
        recipient: propertyEmail,
        subject: SUBJECT_LINE,
        body: EMAIL_BODY,
        cc: 'referyourproperty@getflex.com',
      })
    } catch (error: any) {
      logger.error(error, undefined, 'InviteYourProperty')
    }
  }

  const onSubmit = handleSubmit((data) => {
    // Send propertyEmail through to next screen to be submitted via initiateOffer endpoint
    navigate(`${Routes.Onboarding.RENT_AMOUNT}?propertyEmail=${propertyEmail}`)
  })

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.INVITE_YOUR_PROPERTY}>
      <Box minH="30px" />

      <Headline2>{HEADER}</Headline2>
      <Box minH="30px" />
      <SmallText>{BODY_ONE}</SmallText>
      <Box minH="24px" />
      <SmallText>{BODY_TWO}</SmallText>
      <Box minH="24px" />
      <FormProvider {...formProps}>
        <form onSubmit={onSubmit}>
          <Stack spacing={8}>
            <Box>
              <Field
                name="propertyEmail"
                label=""
                control={control}
                maxLength={150}
                onChange={setPropertyEmail}
                placeholder={PROPERTY_FIELD_PLACEHOLDER}
              />
              <Box>
                <Box minH="24px" />
                {isInviteGenerated ? (
                  <PrimaryButton
                    background="#B28CF480"
                    foreground={theme.colors.jewelPurple}
                    style={{ borderWidth: 1, borderColor: theme.colors.jewelPurple, height: 22 }}
                    processing={isSubmitting}
                  >
                    <Box px="2px">
                      <PaperAirplane />
                    </Box>
                    {BUTTON_DONE_TEXT}
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    foreground={theme.colors.jewelPurple}
                    background="white"
                    style={{
                      borderWidth: 1,
                      borderColor: theme.colors.jewelPurple,
                      height: 22,
                    }}
                    processing={isSubmitting}
                    onClick={openMailToLink}
                  >
                    {BUTTON_TEXT}
                  </PrimaryButton>
                )}
              </Box>
              <Box minH={300} />
              <Box>
                <PrimaryButton processing={isSubmitting} disabled={!isValid} onClick={onSubmit}>
                  {CTA}
                </PrimaryButton>
              </Box>
            </Box>
          </Stack>
        </form>
      </FormProvider>
    </BasePageLayout>
  )
}

export default InviteYourProperty
