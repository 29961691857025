import { setEmbedBPMonthAction } from '@genoa/state-management'

import { useReduxAction } from '../use-redux-action'

export const useSetEmbedBPMonth = () => {
  const setEmbedBPMonth = useReduxAction(setEmbedBPMonthAction)
  return (bpMonth: string) => {
    setEmbedBPMonth({ bpMonth })
  }
}
