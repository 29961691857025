import React from 'react'
import { Analytics } from '@genoa/analytics'
import { BillerPropertyUnit } from '@genoa/domain'
import { BillerPropertyDetails } from '@genoa/middle-end'
import { EMBED_RELINK_DIRECT_INTEGRATION as content } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { Gap, Headline1, InlineButton, PrimaryButton, SmallText } from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'

type DirectIntegrationConfirmAddressProps = {
  onNext: () => unknown
  onAddressNotRight: () => unknown
  property: BillerPropertyDetails
  unit: BillerPropertyUnit
  loading: boolean
  analyticsScreenName: Analytics.Screens
  showPrefills: boolean
  isEmbed: boolean
}

export const DirectIntegrationConfirmAddress = (props: DirectIntegrationConfirmAddressProps) => {
  const theme = useTheme()
  return (
    <BasePageLayout
      analyticsScreenName={props.analyticsScreenName}
      analyticsScreenParams={{ property: props.property }}
      showSettings
    >
      <Gap size="spacing_200" />
      <Container flex={1} flexDirection="column" px="lg">
        <Headline1>{content.HEADER}</Headline1>
        <Gap size="spacing_100" />
        <SmallText>{content.BODY}</SmallText>
        <Gap size="spacing_250" />

        <PropertyContainer>
          <Box>
            <SmallTextStyled>{content.PROPERTY_NAME}</SmallTextStyled>

            <Divider />

            <SmallText
              data-testid="DirectIntegrationConfirmAddressPropertyName"
              textAlign="right"
              data-dd-action-name="Property Name"
            >
              {props.property?.name}
            </SmallText>
          </Box>

          <Gap size="spacing_200" />

          <Box>
            <SmallTextStyled>{content.ADDRESS}</SmallTextStyled>

            <Divider />

            <Flex data-dd-action-name="Address">
              {!!props.unit && (
                <Flex flexDirection="column">
                  {props.unit.address_line_1 && (
                    <SmallText data-testid="DirectIntegrationConfirmAddressAddressLine1">
                      {props.unit.address_line_1}
                    </SmallText>
                  )}
                  {props.unit.address_line_2 && (
                    <SmallText data-testid="DirectIntegrationConfirmAddressAddressLine2">
                      {props.unit.address_line_2}
                    </SmallText>
                  )}
                  <SmallText data-testid="DirectIntegrationConfirmAddressAddressLine3">
                    {props.unit.city}
                    {props.unit.state ? `, ` + props.unit.state : ''}
                    {props.unit.postal_code ? ` ` + props.unit.postal_code : ''}
                  </SmallText>
                </Flex>
              )}
            </Flex>
          </Box>
          <Gap size="spacing_500" />
        </PropertyContainer>
        <Box display="flex" justifyContent="center">
          <SmallText>
            <InlineButton
              isDisabled={props.loading}
              onClick={props.onAddressNotRight}
              textColor={theme.colors.jewelPurple}
              testID="DirectIntegrationConfirmAddressStartOverButton"
            >
              <b>{content.NOT_RIGHT_CTA}</b>
            </InlineButton>
          </SmallText>
        </Box>

        <Gap size="spacing_150" />
        <Box>
          <PrimaryButton
            disabled={props.loading}
            processing={props.loading}
            testID="DirectIntegrationConfirmAddressNext"
            onClick={props.onNext}
          >
            {content.CTA}
          </PrimaryButton>
        </Box>
      </Container>
    </BasePageLayout>
  )
}

const Container = styled(Box)`
  height: 100%;
`
const SmallTextStyled = styled(SmallText)`
  margin: 0;
  text-align: left;
  color: ${(props) => props.theme.colors.dusk};
`

const PropertyContainer = styled(Box)``

const Divider = styled(Box)`
  height: 2px;
  background-color: ${(props) => props.theme.colors.cloud};
  margin: 8px 0;
`
