import { AmplitudeExperiment } from '@genoa/experiments'

import { useAmplitude } from '../../providers/amplitude'

export const useExperiment = <TExperiment extends AmplitudeExperiment>(experiment: TExperiment) => {
  const { getVariant } = useAmplitude()
  const variant = getVariant(experiment.variantID)
  const variantValue = variant?.value
  const variantPayload = variant?.payload
  return { variant, variantValue, variantPayload }
}
