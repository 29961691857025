import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { EmbedPayNowEligibilityFailureCode, SetupAutopilotAction } from '@genoa/domain'
import {
  deepReplaceContent,
  EMBED_SETTINGS_AUTOPAY_TOGGLE,
  EMBED_SETTINGS_AUTOPAY_TOGGLE_CONFIRM_AUTOPAY,
  EMBED_SETTINGS_AUTOPAY_TOGGLE_CONFIRM_EPISODIC,
} from '@genoa/screen-content'
import { FlexError, GeneralApiErrorCodes, useQueryError } from '@genoa/state-management'
import { Flex, Switch } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useAuthState } from '../../../../../contexts'
import { useModal } from '../../../../../contexts'
import {
  useAutopilotEligibility,
  useDefaultErrorModals,
  useEmbedAnalytics,
  useGetEmbedBPMonth,
} from '../../../../../hooks'
import { useDisableAutopilotMutation, useEnableAutopilotMutation } from '../../../../../modules/flexApi'
import { useAnalytics, useLogger } from '../../../../../providers'
import * as Routes from '../../../../../routing/constants'
import { Issue } from '../../../../assets'
import { FullScreenSpinnerLoading, InlineButton } from '../../../../components'
import { SmallText, Text } from '../../../../components/Typography'

interface SettingsAutopayToggleProps {
  setHasAutopayUpdated: (hasAutopayUpdated: boolean) => void
  setIsLoadingToggleAutopay: (isLoadingToggleAutopay: boolean) => void
  runEmbedPayNowEligibilityQuery: () => Promise<{
    data?:
      | {
          bp_start_date: string
          bp_end_date: string
          eligible: boolean
          failure_code?: EmbedPayNowEligibilityFailureCode
        }
      | undefined
    error: FlexError | null
  }>
}

export const SettingsAutopayToggle = (props: SettingsAutopayToggleProps) => {
  const navigate = useNavigate()
  const { uid } = useAuthState()
  const analytics = useAnalytics()
  const { embedFlow } = useEmbedAnalytics()
  const { displayTooManyTriesModal, displayDefaultErrorModal } = useDefaultErrorModals()
  const { isFetchingEligibility, isLoadingEligibility, isUserAutopayEnabled, refetchEligibility } =
    useAutopilotEligibility()
  const [init, setInit] = useState(true)
  const [autopilotStatus, setAutopilotStatus] = useState<SetupAutopilotAction>(SetupAutopilotAction.EPISODIC_ON)
  const [enableRentAutopay, { error: enableRentAutopayError }] = useEnableAutopilotMutation()
  const [disableRentAutopay, { error: disableRentAutopayError }] = useDisableAutopilotMutation()
  const modal = useModal()
  const [autopayStatusIsLoading, setAutopayStatusIsLoading] = useState(false)
  const bpMonth = useGetEmbedBPMonth()
  const logger = useLogger('SettingsAutopayToggle')

  const getToggledStatus = () =>
    autopilotStatus === SetupAutopilotAction.AUTOPAY_ON
      ? SetupAutopilotAction.EPISODIC_ON
      : SetupAutopilotAction.AUTOPAY_ON

  useQueryError(enableRentAutopayError || disableRentAutopayError, {
    onFlexApiError({ data: { error } }) {
      if (error.code === GeneralApiErrorCodes.RATE_LIMIT) {
        setAutopayStatusIsLoading(false)
        props.setIsLoadingToggleAutopay(false)
        setAutopilotStatus(getToggledStatus())
        displayTooManyTriesModal()
        return true
      }
    },
    onAllErrors() {
      setAutopayStatusIsLoading(false)
      props.setIsLoadingToggleAutopay(false)
      setAutopilotStatus(getToggledStatus())
      displayDefaultErrorModal()
    },
  })

  const sendEmbedPayNowEligibilityRequest = async () => {
    const { error } = await props.runEmbedPayNowEligibilityQuery()
    if (error) {
      redirectToContactSupport()
      throw new Error(`Eligibility error: ${error?.code || error}`)
    }
  }

  useEffect(() => {
    if (!bpMonth && init) {
      sendEmbedPayNowEligibilityRequest()
    }
  }, [])

  useEffect(() => {
    if (!isFetchingEligibility) {
      setInit(false)
    }
  }, [isFetchingEligibility])

  useEffect(() => {
    if (!isLoadingEligibility) {
      setAutopilotStatus(isUserAutopayEnabled ? SetupAutopilotAction.AUTOPAY_ON : SetupAutopilotAction.EPISODIC_ON)
    }
  }, [isLoadingEligibility, isUserAutopayEnabled])

  const redirectToContactSupport = () => {
    navigate(Routes.Embed.SUPPORT, { replace: true })
  }

  const setAutopayStatus = async (status: SetupAutopilotAction) => {
    if (!autopayStatusIsLoading) {
      try {
        analytics.logEvent(Analytics.Events.EMBED_SETTINGS_TOGGLE_AUTOPAY_CLICKED, { embedFlow })
        props.setIsLoadingToggleAutopay(true)
        setAutopayStatusIsLoading(true)
        setAutopilotStatus(status)

        status === SetupAutopilotAction.AUTOPAY_ON
          ? await enableRentAutopay({ customerId: uid! }).unwrap()
          : await disableRentAutopay({ customerId: uid! }).unwrap()

        await sendEmbedPayNowEligibilityRequest()
        await refetchEligibility()

        if (status === SetupAutopilotAction.EPISODIC_ON) {
          analytics.logEvent(Analytics.Events.EMBED_SETTINGS_AUTOPAY_OFF_SUCCESS, { embedFlow })
        } else {
          analytics.logEvent(Analytics.Events.EMBED_SETTINGS_AUTOPAY_ON_SUCCESS, { embedFlow })
        }
        props.setIsLoadingToggleAutopay(false)
        setAutopayStatusIsLoading(false)
        props.setHasAutopayUpdated(true)
      } catch (error) {
        const message = error instanceof Error ? error.message : 'An unexpected error occured'
        logger.error('Error in setup', message)
      }
    }
  }

  const onCloseModal = () => {
    modal.close()
  }

  const confirmEpisodicBody1 = deepReplaceContent(EMBED_SETTINGS_AUTOPAY_TOGGLE_CONFIRM_EPISODIC.BODY_1, { bpMonth })
  const confirmEpisodicModal = () => {
    modal.show({
      title: EMBED_SETTINGS_AUTOPAY_TOGGLE_CONFIRM_EPISODIC.HEADER,
      cta: EMBED_SETTINGS_AUTOPAY_TOGGLE_CONFIRM_EPISODIC.CTA,
      onCTAclicked: () => {
        setAutopayStatus(SetupAutopilotAction.EPISODIC_ON)
      },
      hasCloseButton: true,
      render: () => (
        <ModalBody>
          <SmallText>
            <b>{confirmEpisodicBody1}</b>
          </SmallText>
          <SmallTextDescription>{EMBED_SETTINGS_AUTOPAY_TOGGLE_CONFIRM_EPISODIC.BODY_2}</SmallTextDescription>
          <Notice>
            <IssueIcon />
            <NoticeTextEpisodic>{EMBED_SETTINGS_AUTOPAY_TOGGLE_CONFIRM_EPISODIC.NOTICE}</NoticeTextEpisodic>
          </Notice>
          <CancelButton onClick={onCloseModal}>{EMBED_SETTINGS_AUTOPAY_TOGGLE_CONFIRM_EPISODIC.CANCEL}</CancelButton>
        </ModalBody>
      ),
    })
  }

  const confirmAutopayNotice = deepReplaceContent(EMBED_SETTINGS_AUTOPAY_TOGGLE_CONFIRM_AUTOPAY.NOTICE, { bpMonth })
  const confirmAutopayModal = () => {
    modal.show({
      title: EMBED_SETTINGS_AUTOPAY_TOGGLE_CONFIRM_AUTOPAY.HEADER,
      cta: EMBED_SETTINGS_AUTOPAY_TOGGLE_CONFIRM_AUTOPAY.CTA,
      onCTAclicked: () => {
        setAutopayStatus(SetupAutopilotAction.AUTOPAY_ON)
      },
      hasCloseButton: true,
      render: () => (
        <ModalBody>
          <SmallText>{EMBED_SETTINGS_AUTOPAY_TOGGLE_CONFIRM_AUTOPAY.BODY}</SmallText>
          <Notice>
            <IssueIcon />
            <NoticeTextAutopay>{confirmAutopayNotice}</NoticeTextAutopay>
          </Notice>
          <CancelButton onClick={onCloseModal}>{EMBED_SETTINGS_AUTOPAY_TOGGLE_CONFIRM_AUTOPAY.CANCEL}</CancelButton>
        </ModalBody>
      ),
    })
  }

  const onUpdateAutopayStatus = () => {
    if (autopilotStatus === SetupAutopilotAction.AUTOPAY_ON) {
      confirmEpisodicModal()
    } else {
      confirmAutopayModal()
    }
  }

  const content = deepReplaceContent(EMBED_SETTINGS_AUTOPAY_TOGGLE, { bpMonth })
  const bodyText = autopilotStatus === SetupAutopilotAction.AUTOPAY_ON ? content.BODY_AUTOPAY : content.BODY_EPISODIC

  const isLoadingInit = init && isFetchingEligibility

  if (autopayStatusIsLoading || isLoadingInit) {
    return <FullScreenSpinnerLoading />
  }

  return (
    <Frame>
      <FlexText>
        <Text fontWeight="bold">{content.HEADER}</Text>
        <SmallTextBody>{bodyText}</SmallTextBody>
      </FlexText>

      <Flex onClick={onUpdateAutopayStatus}>
        <Switch colorScheme="purple" isChecked={autopilotStatus === SetupAutopilotAction.AUTOPAY_ON} size="lg" />
      </Flex>
    </Frame>
  )
}

const Frame = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.wildflower};
  border-radius: ${({ theme }) => theme.radii.xl};
  flex-direction: row;
  padding: ${({ theme }) => theme.fixedSizes.spacing_100};
`

const FlexText = styled(Flex)`
  flex-direction: column;
  margin-right: ${({ theme }) => theme.fixedSizes.spacing_100};
`

const ModalBody = styled(Flex)`
  flex-direction: column;
  text-align: left;
`

const SmallTextBody = styled(SmallText)`
  color: ${({ theme }) => theme.colors.dusk};
  margin-top: ${({ theme }) => theme.fixedSizes.spacing_100};
`

const Notice = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.cloud};
  border-radius: ${({ theme }) => theme.radii.xl};
  margin-top: ${({ theme }) => theme.fixedSizes.spacing_100};
  margin-bottom: ${({ theme }) => theme.fixedSizes.spacing_100};
  padding: ${({ theme }) => theme.fixedSizes.spacing_100};
`

const NoticeTextEpisodic = styled(SmallText)`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin-left: ${({ theme }) => theme.fixedSizes.spacing_100};
`

const NoticeTextAutopay = styled(SmallText)`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin-left: ${({ theme }) => theme.fixedSizes.spacing_50};
`

const CancelButton = styled(InlineButton)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.jewelPurple};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  margin-bottom: -${({ theme }) => theme.fixedSizes.spacing_125};
  padding-bottom: ${({ theme }) => theme.fixedSizes.spacing_100};
  padding-top: ${({ theme }) => theme.fixedSizes.spacing_100};
  text-align: center;
  width: 100%;
`

const IssueIcon = styled(Issue)`
  min-width: 18px;
`

const SmallTextDescription = styled(SmallText)`
  display: flex;
  margin-bottom: ${({ theme }) => theme.fixedSizes.spacing_100};
  margin-top: ${({ theme }) => theme.fixedSizes.spacing_125};
`
