import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { OVERWRITE_AMOUNT_VALIDATION as content } from '@genoa/screen-content'
import { ordinalSuffixFor, toCents, toDollars, toFormattedDollars } from '@genoa/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import * as Routes from '../../../../../../routing/constants'
import { rentValidator } from '../../../rent-amount/common'
import { BalanceOutOfRange } from './BalanceOutOfRange'
import { SkipMonth } from './SkipMonth'
export interface UpdatedPaymentAmountState {
  readonly updatedPaymentAmount: string
}

export const BalanceOutOfRangeDetailsContainer = () => {
  const location = useLocation()
  const { payment_config, estimated_amount, actual_amount, failure_code, bp_start_date, bp_end_date } =
    location.state.embedPayNowEligibility
  const defaultPaymentAmount = actual_amount / 100

  const overwriteCreateSchema = yup.object({
    updatedPaymentAmount: rentValidator,
  })
  const overwriteHigherCreateSchema = yup.object({
    updatedPaymentAmount: rentValidator.test('cannot-be-lower', content.LESS_THAN_ACTUAL, (updatedPaymentAmount) =>
      updatedPaymentAmount ? updatedPaymentAmount >= defaultPaymentAmount : false
    ),
  })
  const overwriteLowerCreateSchema = yup.object({
    updatedPaymentAmount: rentValidator.test('cannot-be-higher', content.MORE_THAN_ACTUAL, (updatedPaymentAmount) =>
      updatedPaymentAmount ? updatedPaymentAmount <= defaultPaymentAmount : false
    ),
  })
  const overwriteEqualCreateSchema = yup.object({
    updatedPaymentAmount: rentValidator.test('must-be-equal', content.MUST_EQUAL, (updatedPaymentAmount) =>
      updatedPaymentAmount ? updatedPaymentAmount === defaultPaymentAmount : false
    ),
  })

  const getValidationSchema = (canOverwriteToHigherAmount: boolean, canOverwriteToLowerAmount: boolean) => {
    if (canOverwriteToHigherAmount && canOverwriteToLowerAmount) {
      return overwriteCreateSchema
    } else if (!canOverwriteToHigherAmount && canOverwriteToLowerAmount) {
      return overwriteLowerCreateSchema
    } else if (canOverwriteToHigherAmount && !canOverwriteToLowerAmount) {
      return overwriteHigherCreateSchema
    } else if (!canOverwriteToHigherAmount && !canOverwriteToLowerAmount) {
      return overwriteEqualCreateSchema
    }
    return overwriteEqualCreateSchema
  }

  const {
    control,
    handleSubmit,
    formState: { errors: errorsForm },
  } = useForm<UpdatedPaymentAmountState>({
    resolver: yupResolver(
      getValidationSchema(payment_config.can_overwrite_to_higher_amount, payment_config.can_overwrite_to_lower_amount)
    ),
    mode: 'onChange',
  })
  const navigate = useNavigate()

  // TODO: implement confirmSkipMonth function for AUTO_PAY_ON BTL/BTH flow
  // const handleConfirmSkipMonth = () => {
  //   const bpMonth = DateTime.fromFormat(bp_end_date, 'yyyy-MM-dd').monthLong
  //   const bpDate = DateTime.fromFormat(bp_start_date, 'yyyy-MM-dd')
  //   const bpStartDate = `${bpDate.monthLong} ${ordinalSuffixFor(bpDate.day)}`
  //   navigate(Routes.Embed.NOT_COVERED, {
  //     replace: true,
  //     state: { bpMonth, bpStartDate },
  //   })
  // }

  const onSubmitUserInput = handleSubmit((data: UpdatedPaymentAmountState) => {
    if (data.updatedPaymentAmount) {
      const updatedPaymentAmount = toCents(`${data.updatedPaymentAmount}`)
      navigate(Routes.Embed.SIMPLIFIED_EPISODIC, {
        replace: true,
        state: { updatedPaymentAmount },
      })
    }
  })

  // TODO: handle seperate submit logic for AUTO_PAY_ON BTL/BTH flow

  return (
    <BalanceOutOfRange
      handleUpdatePaymentPress={onSubmitUserInput}
      failureCode={failure_code}
      estimateAmount={toFormattedDollars(estimated_amount)}
      actualAmount={toFormattedDollars(actual_amount)}
      defaultPaymentAmount={defaultPaymentAmount}
      control={control}
      formErrors={errorsForm}
    />
  )
}
