import { Outlet, useLocation } from 'react-router-dom'
import { SLCType } from '@genoa/domain'
import { ADD_CARD, CONSOLIDATED_ADD_CARD } from '@genoa/screen-content'
import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { StripeCardElement } from '@stripe/stripe-js'

import { useAprilBpConsolidateCardScreen } from '../../../../../../hooks/experiments/use-april-bp-consolidate-card-screen'
import * as Routes from '../../../../../../routing/constants'
import { Gap, Headline1, SmallText } from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'
import { BillingAddressDetailsContainer } from './billing-address-details/BillingAddressDetailsContainer'
import { BillingAddress } from './billing-address-types'
import { CardInput } from './card-input/CardInput'

type RegisterCardProps = {
  onCardInputReady: (cardElement: StripeCardElement) => void
  onPressAddCTA: (billingAddress: BillingAddress) => void
  onCardChange: (isCompleted: boolean) => void
  cardFormError: string
  billingAddressError: string
  isLoading: boolean
  processingFeePercentage?: number
  bodyCopy?: string
}

export const RegisterCard = (props: RegisterCardProps) => {
  const theme = useTheme()
  const location = useLocation()
  const { consolidateCardScreen } = useAprilBpConsolidateCardScreen()
  const content = consolidateCardScreen ? CONSOLIDATED_ADD_CARD : ADD_CARD

  const isUpdatingCardDetails = location.pathname === Routes.Onboarding.CARD_REGISTER

  return (
    <BasePageLayout>
      <Box hidden={!isUpdatingCardDetails}>
        <Gap size="spacing_200" />

        <Container>
          <Headline1>{content.HEADER}</Headline1>

          <Gap size="spacing_150" />
          {consolidateCardScreen && (
            <>
              <SmallText>{content.BODY}</SmallText>
              <Gap size="spacing_100" />
            </>
          )}
          {props.bodyCopy && (
            <>
              <SmallText>{props.bodyCopy}</SmallText>
              <Gap size="spacing_250" />
            </>
          )}

          <Box mt={4}>
            <SmallText>{content.CARD_DETAILS}</SmallText>
            <CardInput
              onCardInputReady={props.onCardInputReady}
              onCardChange={props.onCardChange}
              cardFormError={props.cardFormError}
            />
          </Box>
          {consolidateCardScreen && (
            <>
              <Gap size="spacing_50" />
              <SmallText color={theme.colors.dusk}>{content.FEE_INFO}</SmallText>
            </>
          )}
          <Gap size="spacing_200" />

          <BillingAddressDetailsContainer
            billingAddressError={props.billingAddressError}
            onPressAddCTA={props.onPressAddCTA}
            isProcessingRegisterCard={props.isLoading}
            updateBillingNavigate={Routes.Onboarding.CARD_UPDATE_BILLING_ADDRESS}
          />
        </Container>
      </Box>
      <Outlet />
    </BasePageLayout>
  )
}

const Container = styled(Box)`
  height: 100%;
`
