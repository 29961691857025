import React from 'react'
import { Analytics } from '@genoa/analytics'
import { indefiniteArticle } from '@genoa/utils'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Headline1, InlineButton, PrimaryButton, SmallText } from '../../../components'
import { BasePageLayout } from '../../../layouts'
import { PrefillConfirmBanner } from './PrefillConfirmBanner'

type RentPortalDetailsProps = {
  onBack: () => unknown
  onSignIn: () => unknown
  onWhatIfNoPortalAccount: () => unknown
  onStartOver: () => unknown
  propertyName: string
  portalName: string
  portalLogoUrl: string
  analyticsScreenName: Analytics.Screens
  showPrefills: boolean
}

export const RentPortalDetails = (props: RentPortalDetailsProps) => (
  <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
    <Box minH="30px" />
    <Headline1>Connect your rent portal</Headline1>
    <Box minH="15px" />
    <ConfirmRentPortalText
      propertyName={props.propertyName}
      portalName={props.portalName}
      showPrefills={props.showPrefills}
    />
    <Box minH="10px" />
    <Box>
      <SmallText>
        <InlineButton onClick={props.onStartOver} testID="RentPortalDetailsStartOverButton">
          Not right? <b>Start over with a new address</b>
        </InlineButton>
      </SmallText>
    </Box>
    <Box minH="30px" />
    <RentPortalContainer flexShrink={0}>
      <Flex alignItems="center" justifyContent="center">
        <RentPortalLogo src={props.portalLogoUrl} />
      </Flex>

      <Box minH="30px" />

      <SmallText>Connect to {props.portalName} to authorize Flex to pay your rent each month.</SmallText>

      <Box minH="10px" />

      <Box>
        <SmallText fontWeight="bold">
          <InlineButton onClick={props.onWhatIfNoPortalAccount}>
            <b>
              What if I don&rsquo;t have {indefiniteArticle(props.portalName)} {props.portalName} account?
            </b>
          </InlineButton>
        </SmallText>
      </Box>

      <Box minH="30px" />

      <PrimaryButton testID="RentPortalSignIn" onClick={props.onSignIn}>
        Sign in
      </PrimaryButton>
    </RentPortalContainer>
    <Box minH="30px" />
  </BasePageLayout>
)

interface ConfirmRentPortalTextProps {
  readonly propertyName: string
  readonly portalName: string
  readonly showPrefills: boolean
}

const ConfirmRentPortalText = (props: ConfirmRentPortalTextProps) => {
  if (props.showPrefills) {
    return (
      <>
        <PrefillConfirmBanner />
        <Box minH="15px" />
        <Box>
          <SmallText>
            We&rsquo;ve confirmed that you live at <SmallText fontWeight="bold">{props.propertyName}</SmallText>, which
            uses a rent portal called <SmallText fontWeight="bold">{props.portalName}</SmallText> to collect rent
            payments online.
          </SmallText>
        </Box>
      </>
    )
  }

  return (
    <Box>
      <SmallText>
        {props.propertyName} uses a rent portal called <SmallText fontWeight="bold">{props.portalName}</SmallText> to
        collect rent payments online.
      </SmallText>
    </Box>
  )
}

const RentPortalContainer = styled(Box)`
  padding: 25px;
  border: 1px solid ${(props) => props.theme.colors.transparentCardBorderColor};
  border-radius: ${(props) => props.theme.radii.lg};
  overflow: hidden;
`

const RentPortalLogo = styled('img')`
  max-width: 100%;
  height: 40px;
`

const SmallTextInvisible = styled(SmallText)`
  color: transparent;
`
