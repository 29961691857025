import { Control, FormState } from 'react-hook-form'
import { Analytics } from '@genoa/analytics'
import { EMBED_BTL_OR_BTH as content, replaceContent } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { useEmbedAnalytics } from '../../../../../../hooks'
import { ServiceIssue } from '../../../../../assets'
import {
  Field,
  Gap,
  Headline2,
  InlineButton,
  LineDivider,
  MoneyInput,
  NoMarginLineDivider,
  PrimaryButton,
  SmallText,
  Text,
} from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'
import { UpdatedPaymentAmountState } from './BalanceOutOfRangeContainer'

type BTH_BTL = 'BALANCE_TOO_HIGH' | 'BALANCE_TOO_LOW'

// TODO: Implement SkipMonth Button && SkipMonth Modal
interface BalanceOutOfRangeProps {
  estimateAmount: string
  actualAmount: string
  defaultPaymentAmount: number
  failureCode: BTH_BTL
  handleUpdatePaymentPress: () => void
  control: Control<UpdatedPaymentAmountState>
  formErrors: FormState<UpdatedPaymentAmountState>['errors']
}

export const BalanceOutOfRange = (props: BalanceOutOfRangeProps) => {
  const theme = useTheme()
  const { embedProperties } = useEmbedAnalytics()

  const firstPaymentHeader = replaceContent(content.HEADER, {
    higherOrLower: props.failureCode === 'BALANCE_TOO_HIGH' ? 'higher' : 'lower',
  })
  return (
    <BasePageLayout
      analyticsScreenName={Analytics.Screens.EMBED_BALANCE_OUT_OF_RANGE}
      analyticsScreenParams={embedProperties}
      isEmbedSignup
      showSettings
    >
      <Container flex={1} flexDirection="column" px="md">
        <Gap size="spacing_100" />
        <ServiceIssueHeader>
          <ServiceIssue />
          <ServiceIssueText>{content.SERVICE_ISSUE}</ServiceIssueText>
        </ServiceIssueHeader>
        <Gap size="spacing_100" />
        <Headline2>{firstPaymentHeader}</Headline2>
        <Gap size="spacing_100" />

        <SmallText>{content.BODY}</SmallText>
        <Gap size="spacing_150" />
        <RentBillDetails>{content.BILL_DETAILS}</RentBillDetails>
        <Gap size="spacing_50" />
        <NoMarginLineDivider />
        <Gap size="spacing_75" />
        <Row>
          <SmallText fontWeight="bold">{content.ORIGINAL}</SmallText>
          <SmallText fontWeight="bold">{props.estimateAmount}</SmallText>
        </Row>
        <LineDivider />
        <Row>
          <SmallText fontWeight="bold">{content.ACTUAL}</SmallText>
          <SmallText fontWeight="bold">{props.actualAmount}</SmallText>
        </Row>
        <LineDivider />
        <TotalDueContainer>
          <SmallText fontWeight="bold">{content.TOTAL}</SmallText>
          <SmallText fontWeight="bold">{props.actualAmount}</SmallText>
        </TotalDueContainer>
        <Gap size="spacing_300" />
        <Field
          input={MoneyInput}
          label={<SmallText fontWeight="bold">{content.RENT_AMOUNT}</SmallText>}
          name="updatedPaymentAmount"
          control={props.control}
          defaultValue={`${props.defaultPaymentAmount}`}
          error={props.formErrors.updatedPaymentAmount}
          testID="UpdatedRentInput"
        />
        <Footer>
          <Gap size="spacing_200" />
          {/* <SkipMonthButton>
            <InlineButton
              onClick={props.handleSkipMothPress}
              textColor={theme.colors.jewelPurple}
              testID="DontUseFlexThisMonthButton"
            >
              <b>{content.SKIP_MONTH}</b>
            </InlineButton>
          </SkipMonthButton> */}
          <PrimaryButton
            disabled={!!props.formErrors.updatedPaymentAmount}
            onClick={props.handleUpdatePaymentPress}
            testID="UpdatePaymentAmountButton"
          >
            {content.CTA}
          </PrimaryButton>
          <Gap size="spacing_225" />
        </Footer>
      </Container>
    </BasePageLayout>
  )
}

const Container = styled(Box)`
  height: 100%;
  padding-left: ${({ theme }) => theme.fixedSizes.spacing_100};
  padding-right: ${({ theme }) => theme.fixedSizes.spacing_100};
`
const RentBillDetails = styled(SmallText)`
  color: ${({ theme }) => theme.colors.dusk};
`
const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.fixedSizes.spacing_50};
`

const ServiceIssueHeader = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.radii.modal};
  background-color: ${({ theme }) => theme.colors.cloud};
  padding: ${({ theme }) => theme.fixedSizes.spacing_100};
`
const ServiceIssueText = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-top: ${({ theme }) => theme.fixedSizes.spacing_50};
`
const SkipMonthButton = styled(Headline2)`
  padding-bottom: ${({ theme }) => theme.fixedSizes.spacing_150};
  line-height: ${({ theme }) => theme.fontSizes.lg};
  font-size: ${({ theme }) => theme.fontSizes.button};
`

const Footer = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const TotalDueContainer = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.radii.md};
  background-color: ${({ theme }) => theme.colors.cloud};
  padding: ${({ theme }) => theme.fixedSizes.spacing_37_5} ${({ theme }) => theme.fixedSizes.spacing_50}
    ${({ theme }) => theme.fixedSizes.spacing_37_5} ${({ theme }) => theme.fixedSizes.spacing_50};
`
