import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { VerificationStatus } from '@genoa/domain'
import { AmplitudeFeatureFlag } from '@genoa/experiments'
import { OfferCurrentEvaluateStep, OfferStates } from '@genoa/middle-end'
import { EvaluationInstruction, useQueryError } from '@genoa/state-management'

import { useAuthState } from '../../../../contexts'
import { useOnboardingInAppIncomeVerificationExperiment } from '../../../../hooks'
import { useAmplitudeFeatureFlag } from '../../../../hooks/use-amplitude-feature-flag'
import { useOfferState } from '../../../../modules/flex2/use-state'
import { useContinueEvaluationMutation } from '../../../../modules/flexApi'
import { useAnalytics, useLogger } from '../../../../providers'
import * as Routes from '../../../../routing/constants'
import { FullScreenSpinnerLoading } from '../../../components'

export const SoftCreditCheckStageContainer = () => {
  const analytics = useAnalytics()
  const navigate = useNavigate()
  const offer = useOfferState()
  const { uid } = useAuthState()
  const loggerV2 = useLogger('SoftCreditCheckStageContainer')
  const { isOnboardingInAppIncomeVerificationEnabled } = useOnboardingInAppIncomeVerificationExperiment()
  const { enabled: docVEnabled } = useAmplitudeFeatureFlag(AmplitudeFeatureFlag.DocVWeb)
  const [continueEvaluation, { error: continueEvaluationError }] = useContinueEvaluationMutation()

  const handleIncomeVerificationNavigation = (
    verificationStatus: VerificationStatus,
    instruction: EvaluationInstruction
  ) => {
    switch (verificationStatus) {
      case VerificationStatus.MANUAL_REVIEW:
        return navigate(Routes.Onboarding.INCOME_VERIFICATION_PROCESSING_OFFER)
      case VerificationStatus.COMPLETED:
      case VerificationStatus.SUBMITTED:
        return navigate(Routes.Onboarding.INCOME_VERIFICATION_WAITING_OFFER)
      default:
        if (!isOnboardingInAppIncomeVerificationEnabled) {
          return navigate(Routes.Onboarding.NOT_APPROVED, { replace: true })
        }

        if (instruction === EvaluationInstruction.NeedsFurtherVerification) {
          return navigate(Routes.Onboarding.VERIFY_YOUR_INCOME)
        }
        // All other VerificationStatus states need to submit or re-submit IV
        return navigate(Routes.Onboarding.WHATS_YOUR_INCOME)
    }
  }

  const handleDocumentVerificationNavigation = (instruction: EvaluationInstruction, resubmit?: boolean) => {
    if (!docVEnabled) {
      return navigate(Routes.Onboarding.NOT_APPROVED, { replace: true })
    }

    // Note, the implementation was slightly different in mobile, matching for parity but also matching the existing implementation
    switch (instruction) {
      case EvaluationInstruction.InProgress:
        return navigate(Routes.Onboarding.DOCUMENT_VERIFICATION)
      case EvaluationInstruction.Resubmit:
        return navigate(Routes.Onboarding.DOCUMENT_VERIFICATION_RESUBMIT)
      case EvaluationInstruction.ErrorState:
        break
    }

    if (resubmit) {
      return navigate(Routes.Onboarding.DOCUMENT_VERIFICATION_RESUBMIT)
    }
  }

  const handleResumeOnboardingSoftCreditCheckState = async () => {
    if (offer.offer.offer_state === OfferStates.INITIATED) {
      return navigate(Routes.Onboarding.SOFT_CREDIT_CHECK)
    }

    const response = await continueEvaluation({ customerId: uid! }).unwrap()

    const currentOfferState = response.data.risk_offer.offer_state
    const currentEvaluateStep = response.data.risk_offer.current_evaluate_step

    switch (currentOfferState) {
      case OfferStates.DENIED:
        return navigate(Routes.Onboarding.NOT_APPROVED, { replace: true })
      case OfferStates.PENDING_ACCEPT:
        return navigate(Routes.Onboarding.CUSTOMIZE_SCHEDULE)
      case OfferStates.PENDING_EVALUATE:
        if (currentEvaluateStep === OfferCurrentEvaluateStep.VERIFY_INCOME) {
          handleIncomeVerificationNavigation(response.data.income_verification_status!, response.data.instruction)
        } else if (currentEvaluateStep === OfferCurrentEvaluateStep.IDENTITY_DOCUMENT_VERIFICATION) {
          handleDocumentVerificationNavigation(
            response.data.instruction,
            response.data?.risk_offer?.onboarding_identity_document_verification?.resubmit
          )
        }

        break
      default:
        return handleIncomeVerificationNavigation(response.data.income_verification_status!, response.data.instruction)
    }
  }

  useQueryError(continueEvaluationError, {
    onAllErrors() {
      loggerV2.error('Error polling Continue Evaluation')
      analytics.logEvent(Analytics.Events.IV_CONTINUE_EVALUATION_FAILURE)
    },
  })

  useEffect(() => {
    if (!uid) {
      return
    }

    handleResumeOnboardingSoftCreditCheckState()
  }, [uid])

  return <FullScreenSpinnerLoading />
}
