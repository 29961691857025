import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { GeneralApiErrorCodes, useQueryError } from '@genoa/state-management'

import * as Routes from '../../routing/constants'
import { useEmbedBlockingScreens } from './use-embed-blocking-screens'

export const useCheckPayNowEligibility = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const updatedPaymentAmount = location.state?.updatedPaymentAmount
  const { checkBlockingStates, embedPayNowEligibility, embedPayNowEligibilityError, runEmbedPayNowEligibilityQuery } =
    useEmbedBlockingScreens({})

  useEffect(() => {
    if (!embedPayNowEligibility) {
      runEmbedPayNowEligibilityQuery()
    }
  }, [embedPayNowEligibility])

  useQueryError(embedPayNowEligibilityError, {
    onAllErrors() {
      if (embedPayNowEligibilityError?.code === GeneralApiErrorCodes.RATE_LIMIT) {
        return navigate(Routes.Embed.CONGRATS, { state: { errorCode: GeneralApiErrorCodes.RATE_LIMIT } })
      }
      navigate(Routes.Embed.CONGRATS, { state: { errorCode: 'default' } })
    },
  })

  useEffect(() => {
    if (embedPayNowEligibility && !updatedPaymentAmount) {
      checkBlockingStates()
    }
  }, [embedPayNowEligibility, updatedPaymentAmount])

  return {
    embedPayNowEligibility,
    isPaynowEligibilityLoading: !embedPayNowEligibility || !embedPayNowEligibility.eligible,
  }
}
