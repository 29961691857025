import { AprilBpResumeOnboardingSkippedExperiment } from '@genoa/experiments'

import { useExperiment } from './use-experiment'

interface AprilBpResumeOnboardingSkipped {
  skipResumeOnboarding: boolean
}

const CONTROL = false

export const useAprilBpResumeOnboardingSkipped = (): AprilBpResumeOnboardingSkipped => {
  const { variantValue } = useExperiment(AprilBpResumeOnboardingSkippedExperiment)
  if (variantValue) {
    return { skipResumeOnboarding: variantValue === 'treatment' }
  }
  return {
    skipResumeOnboarding: CONTROL,
  }
}
