import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EmbedPayNowEligibility, EmbedPayNowEligibilityFailureCode } from '@genoa/domain'
import { BusinessLogicExceptionErrorCode, FlexError } from '@genoa/state-management'
import { getBpMonth, ordinalSuffixFor } from '@genoa/utils'
import { DateTime } from 'luxon'

import { useAuthState } from '../../contexts'
import { useLazyEmbedPayNowEligibilityQuery } from '../../modules/flexApi'
import * as Routes from '../../routing/constants'
import { useEmbed } from './use-embed'
import { useSetEmbedBPMonth } from './use-set-embed-bp-month'

interface UseEmbedBlockScreensProps {
  defaultNavigateToCongrats?: boolean
}

export const useEmbedBlockingScreens = (props: UseEmbedBlockScreensProps) => {
  const navigate = useNavigate()
  const { client, token } = useEmbed()
  const { uid } = useAuthState()
  const [embedPayNowEligibility, setEmbedPayNowEligibility] = useState<EmbedPayNowEligibility>()
  const [embedPayNowEligibilityError, setEmbedPayNowEligibilityError] = useState<FlexError>()
  const setEmbedBPMonth = useSetEmbedBPMonth()

  const [embedPayNowEligibilityQuery] = useLazyEmbedPayNowEligibilityQuery()

  const runEmbedPayNowEligibilityQuery = async () => {
    try {
      const { data, error } = await embedPayNowEligibilityQuery({ client, customerId: uid!, token }).unwrap()
      if (data) {
        setEmbedBPMonth(getBpMonth(data.bp_end_date))
        setEmbedPayNowEligibility(data)
      }
      if (error) {
        setEmbedPayNowEligibilityError(error)
      }
      return { data, error }
    } catch (error) {
      if (error) {
        setEmbedPayNowEligibilityError({ code: BusinessLogicExceptionErrorCode.B_UNCAUGHT_EXCEPTION, message: '' })
      }
      return { error } as { error: FlexError }
    }
  }

  function checkBlockingStates() {
    if (embedPayNowEligibility && !embedPayNowEligibility?.eligible) {
      const bpMonth = getBpMonth(embedPayNowEligibility.bp_end_date)

      switch (embedPayNowEligibility?.failure_code) {
        case EmbedPayNowEligibilityFailureCode.AUTOPAY_ON:
          navigate(Routes.Embed.ALREADY_FLEXING_YOUR_RENT, { replace: true, state: { bpMonth } })
          break
        case EmbedPayNowEligibilityFailureCode.BP_WINDOW:
          const bpDate = DateTime.fromFormat(embedPayNowEligibility.bp_start_date, 'yyyy-MM-dd')
          const bpStartDate = `${bpDate.monthLong} ${ordinalSuffixFor(bpDate.day)}`
          navigate(Routes.Embed.NOT_RENT_DAY, {
            replace: true,
            state: { bpMonth, bpStartDate },
          })
          break
        case EmbedPayNowEligibilityFailureCode.RELINK_PROPERTY:
          navigate(Routes.Embed.RELINK_DIRECT_INTEGRATION_CONFIRMATION)
          break
        case EmbedPayNowEligibilityFailureCode.RENT_PAYMENT_IN_PROGRESS:
        case EmbedPayNowEligibilityFailureCode.RENT_PAID:
          navigate(Routes.Embed.RENT_SPLIT_PROGRESS, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.INVALID_PRODUCT:
          navigate(Routes.Embed.BUILD_CREDIT_IN_APP, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.INVALID_BALANCE:
          navigate(Routes.Embed.SERVICE_ISSUE, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.CREDIT_LINE_DECLINED:
          navigate(Routes.Embed.CREDIT_LINE_DECLINED, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.CREDIT_LINE_SUSPENDED:
          navigate(Routes.Embed.CREDIT_LINE_SUSPENDED, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.UNKNOWN:
          navigate(Routes.Embed.SOMETHING_WENT_WRONG, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.BALANCE_TOO_HIGH:
        case EmbedPayNowEligibilityFailureCode.BALANCE_TOO_LOW:
          navigate(Routes.Embed.BALANCE_OUT_OF_RANGE, {
            replace: true,
            state: { embedPayNowEligibility },
          })

          break
        default:
          navigate(Routes.Embed.SOMETHING_WENT_WRONG, { replace: true })
          break
      }
    } else if (props.defaultNavigateToCongrats) {
      navigate(Routes.Embed.CONGRATS, { replace: true })
    }
  }

  return { checkBlockingStates, embedPayNowEligibility, embedPayNowEligibilityError, runEmbedPayNowEligibilityQuery }
}
