import { useLocation } from 'react-router-dom'
import { AmplitudeFeatureFlag } from '@genoa/experiments'
import { usePaymentStatusHookFactory } from '@genoa/state-management/lib/modules/api/payment'

import { useAuthState } from '../../contexts'
import { useGetPaymentStatusQuery } from '../../modules/flexApi'
import { useSecureLineOfCredit } from '../secure-line-of-credit'
import { useAmplitudeFeatureFlag } from '../use-amplitude-feature-flag'

export const usePaymentStatus = () => {
  const { slcType } = useSecureLineOfCredit()
  const { enabled: midFebRefactorEnabled } = useAmplitudeFeatureFlag(AmplitudeFeatureFlag.PaymentStatusRefactorMidFeb)
  const enabled = midFebRefactorEnabled || !!slcType
  // use updatedPaymentAmount from BTH/BTL resolution for overrideBillAmount
  const location = useLocation()
  const overrideBillAmount = location.state?.updatedPaymentAmount
  return usePaymentStatusHookFactory(useGetPaymentStatusQuery, useAuthState, enabled, undefined, overrideBillAmount)
}
