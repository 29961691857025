import { useEffect } from 'react'
import { Analytics } from '@genoa/analytics'
import { SelfPayPaymentLabel } from '@genoa/domain'
import { CONGRATS, CONGRATS_CONTENT_AUTOPAY_EXPERIMENT, deepReplaceContent } from '@genoa/screen-content'
import { getDateTime, getResumeDate } from '@genoa/utils'
import { Box, Flex } from '@chakra-ui/react'

import {
  useAutopilotEligibility,
  useEmbedAnalytics,
  useFlexAnywhereUser,
  useGetAccount,
  useIsEmbed,
  useOnboardingAutopayToggleExperiment,
} from '../../../hooks'
import { useAnalytics } from '../../../providers'
import {
  CongratsFlag as CongratsImageFlag,
  HeroBouquet as CongratsImageBouquet,
  StatusIconOne,
  StatusIconTwo,
} from '../../assets'
import { Headline1, Text } from '../../components'
import { AppStoreButton } from '../../components/AppStoreButton'
import { PlayStoreButton } from '../../components/PlayStoreButton'
import { FullScreenSpinnerLoading } from '../../components/SpinnerLoading/FullScreenSpinnerLoading'
import { BasePageLayout } from '../../layouts'

type CongratsProps = {
  analyticsScreenName: Analytics.Screens
  paymentMethod: string
}

const storeBadgeStyles = {
  maxHeight: '65px',
  minHeight: '65px',
  transform: 'scale(0.7)',
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '24px',
  marginTop: '16px',
}

export const Congrats = ({ analyticsScreenName, paymentMethod }: CongratsProps) => {
  const isOutOfNetworkUser = useFlexAnywhereUser()
  const { isUserAutopayEnabled, isLoadingEligibility } = useAutopilotEligibility()
  const { embedFlow, embedProperties } = useEmbedAnalytics()
  const analytics = useAnalytics()
  const isEmbed = useIsEmbed()
  useGetAccount()
  const { isOnboardingAutopayToggleEnabled } = useOnboardingAutopayToggleExperiment()

  const today = getDateTime()
  const resumeDate = getResumeDate(today)

  const autopayExperimentContent = deepReplaceContent(CONGRATS_CONTENT_AUTOPAY_EXPERIMENT, {
    month: resumeDate.monthLong!,
  })

  const image =
    isUserAutopayEnabled && !isOnboardingAutopayToggleEnabled ? <CongratsImageFlag /> : <CongratsImageBouquet />

  const paymentMethodString = paymentMethod === SelfPayPaymentLabel.DDA ? 'bank account' : 'debit card'

  const congratsContent = deepReplaceContent(CONGRATS, { paymentMethod: paymentMethodString })

  // Initializing with default content, will be overridden based on if the user is FA or autopay on/off
  let content = congratsContent.CONTENT
  let rememberCard = congratsContent.REMEMBER_CARD

  if (isOutOfNetworkUser) {
    content = congratsContent.CONTENT
    rememberCard = congratsContent.FA_REMEMBER_CARD
  } else if (isOnboardingAutopayToggleEnabled) {
    content = autopayExperimentContent.CONTENT
    if (isUserAutopayEnabled) {
      rememberCard = autopayExperimentContent.REMEMBER_CARD_AUTOPAY_ON
    } else {
      rememberCard = autopayExperimentContent.REMEMBER_CARD_AUTOPAY_OFF
    }
  }

  const onClickAppStore = () => {
    if (isEmbed) {
      analytics.logEvent(Analytics.Events.EMBED_CONGRATS_AUTOPAY_ON_CTA_APPLE, {
        embedFlow,
      })
    }
  }

  const onClickPlayStore = () => {
    if (isEmbed) {
      analytics.logEvent(Analytics.Events.EMBED_CONGRATS_AUTOPAY_ON_CTA_GOOGLE, {
        embedFlow,
      })
    }
  }

  useEffect(() => {
    if (isEmbed) {
      analytics.logScreenView(Analytics.Screens.EMBED_CONGRATS_AUTOPAY_ON, embedProperties)
    }
  }, [isEmbed])

  if (isLoadingEligibility) {
    return <FullScreenSpinnerLoading />
  }

  return (
    <BasePageLayout analyticsScreenName={analyticsScreenName} showSettings={isEmbed}>
      <Box minH={isUserAutopayEnabled ? '15px' : '42px'} />

      <Flex flexGrow={1} flexShrink={0} flexDirection="column" data-testid="CongratsPage">
        <Flex align="center" justify="center">
          {image}
        </Flex>
        <Box minH={isUserAutopayEnabled ? '36px' : '20px'} />
        <Headline1 textAlign="center" data-testid="CongratsHeader">
          {content.HEADER}
        </Headline1>
        <Box minH="16px" />
        <Text textAlign="center" data-testid="CongratsBody">
          {content.BODY}
        </Text>
        <Text textAlign="center" data-testid="CongratsBodyTwo">
          {content.BODY_ONE}
          <Text fontWeight="bold"> {resumeDate.monthLong} </Text>
          {content.BODY_TWO}
        </Text>
        <Box minH="32px" />
        {/* Remember component with tips 1 and 2 */}
        <Flex flexShrink={0} direction="column" bg="cloud" borderRadius="8px" p="16px" mb="32px">
          <Text fontWeight="bold" data-testid="CongratsRememberHeader">
            {rememberCard.HEADER}
          </Text>
          <Flex pt="16px" alignItems="flex-start">
            <Box pr="4px" mt="5px" flexShrink={0}>
              <StatusIconOne />
            </Box>
            <Text fontSize="12px" ml="5px" lineHeight="16.8px" data-testid="RememberCardBodyOne">
              <Text fontSize="12px" fontWeight="bold">
                {rememberCard.BODY_ONE_BOLD}{' '}
              </Text>
              {rememberCard.BODY_ONE}
            </Text>
          </Flex>
          <Flex mt="16px" alignItems="flex-start">
            <Box pr="4px" mt="5px" flexShrink={0}>
              <StatusIconTwo />
            </Box>
            <Text fontSize="12px" ml="5px" lineHeight="16.8px" data-testid="RememberCardBodyTwo">
              <Text fontSize="12px" fontWeight="bold">
                {rememberCard.BODY_TWO_BOLD}{' '}
              </Text>
              {rememberCard.BODY_TWO}
            </Text>
          </Flex>
        </Flex>
        <Flex
          flexShrink={0}
          direction="column"
          align="center"
          justify="center"
          bg="cloud"
          borderRadius="8px"
          px="15px"
          py="30px"
        >
          <Text fontSize="12px" textAlign="center" data-testid="CongratsDownload">
            {CONGRATS.CONTENT.DOWNLOAD}
          </Text>

          <Flex {...storeBadgeStyles}>
            <AppStoreButton onClickAppStore={onClickAppStore} />
            <PlayStoreButton onClickPlayStore={onClickPlayStore} />
          </Flex>
        </Flex>
      </Flex>

      <Box minH="30px" />
    </BasePageLayout>
  )
}
