import { useLocation } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { deepReplaceContent, EMBED_ALREADY_FLEXING_YOUR_RENT } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useEmbedAnalytics } from '../../../../../../hooks'
import { useAnalytics } from '../../../../../../providers'
import { NumberCircleOne, NumberCircleThree, NumberCircleTwo, RocketShipLarge } from '../../../../../assets'
import { AppStore, Gap, Headline2, SmallText, Text } from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'

export const EmbedAlreadyFlexingYourRent = () => {
  const analytics = useAnalytics()
  const location = useLocation()
  const { embedFlow, embedProperties } = useEmbedAnalytics()
  const content = deepReplaceContent(EMBED_ALREADY_FLEXING_YOUR_RENT, {
    bpMonth: location.state.bpMonth,
  })

  const onClickAppStore = () => {
    analytics.logEvent(Analytics.Events.EMBED_BLOCKING_AUTOPAY_ON_CTA_APPLE, { embedFlow })
  }

  const onClickPlayStore = () => {
    analytics.logEvent(Analytics.Events.EMBED_BLOCKING_AUTOPAY_ON_CTA_GOOGLE, { embedFlow })
  }

  return (
    <BasePageLayout
      analyticsScreenName={Analytics.Screens.EMBED_BLOCKING_AUTOPAY_ON}
      analyticsScreenParams={embedProperties}
      showSettings
    >
      <Container>
        <Gap size="spacing_300" />
        <RocketShipLarge />
        <Gap size="spacing_200" />
        <Headline2>{content.HEADER}</Headline2>
        <Gap size="spacing_200" />
        <ListContainer>
          <ListHeader>{content.LIST_ITEM_HEADER}</ListHeader>
          <Row>
            <ListIcon>
              <NumberCircleOne />
            </ListIcon>
            <ListSmallText>{content.LIST_ITEM_1}</ListSmallText>
          </Row>
          <Row>
            <ListIcon>
              <NumberCircleTwo />
            </ListIcon>
            <ListSmallText>{content.LIST_ITEM_2}</ListSmallText>
          </Row>
          <Row>
            <ListIcon>
              <NumberCircleThree />
            </ListIcon>
            <ListSmallText>{content.LIST_ITEM_3}</ListSmallText>
          </Row>
        </ListContainer>
        <Gap size="spacing_200" />
        <AppStore notice={content.NOTICE} onClickAppStore={onClickAppStore} onClickPlayStore={onClickPlayStore} />
      </Container>
    </BasePageLayout>
  )
}

const Container = styled(Flex)`
  align-items: center;
  flex-direction: column;
  text-align: center;
`

const Row = styled(Flex)`
  align-items: center;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.fixedSizes.spacing_100};
  text-align: left;
`

const ListContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.wildflower};
  border-radius: ${({ theme }) => theme.radii.modal};
  flex-direction: column;
  margin-top: ${({ theme }) => theme.fixedSizes.spacing_50};
  padding: ${({ theme }) => theme.fixedSizes.spacing_100};
  width: 100%;
`

const ListHeader = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: ${({ theme }) => theme.fixedSizes.spacing_100};
`

const ListIcon = styled(Flex)`
  flex-shrink: 0;
`

const ListSmallText = styled(SmallText)`
  margin-left: ${({ theme }) => theme.fixedSizes.spacing_75};
`
